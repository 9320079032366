/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 30, 2017 */

@font-face {
    font-family: 'panefresco';
    src: url('fonts/panefresco/panefresco1wtregular-webfont.woff2') format('woff2'),
         url('fonts/panefresco/panefresco1wtregular-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'panefresco';
    src: url('fonts/panefresco/panefresco250wtregular-webfont.woff2') format('woff2'),
         url('fonts/panefresco/panefresco250wtregular-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'panefresco';
    src: url('fonts/panefresco/panefresco400wtregular-webfont.woff2') format('woff2'),
         url('fonts/panefresco/panefresco400wtregular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'panefresco';
    src: url('fonts/panefresco/panefresco500wtregular-webfont.woff2') format('woff2'),
         url('fonts/panefresco/panefresco500wtregular-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'panefresco';
    src: url('fonts/panefresco/panefresco600wtregular-webfont.woff2') format('woff2'),
         url('fonts/panefresco/panefresco600wtregular-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'panefresco';
    src: url('fonts/panefresco/panefresco750wtregular-webfont.woff2') format('woff2'),
         url('fonts/panefresco/panefresco750wtregular-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'panefresco';
    src: url('fonts/panefresco/panefresco800wtregular-webfont.woff2') format('woff2'),
         url('fonts/panefresco/panefresco800wtregular-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'panefresco';
    src: url('fonts/panefresco/panefresco999wtregular-webfont.woff2') format('woff2'),
         url('fonts/panefresco/panefresco999wtregular-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'zilla_slab';
    src: url('fonts/zillaslab/zillaslab-light-webfont.woff2') format('woff2'),
         url('fonts/zillaslab/zillaslab-light-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'zilla_slab';
    src: url('fonts/zillaslab/zillaslab-medium-webfont.woff2') format('woff2'),
         url('fonts/zillaslab/zillaslab-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'zilla_slab';
    src: url('fonts/zillaslab/zillaslab-semibold-webfont.woff2') format('woff2'),
         url('fonts/zillaslab/zillaslab-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'zilla_slab_highlight';
    src: url('fonts/zillaslab/zillaslabhighlight-bold-webfont.woff2') format('woff2'),
         url('fonts/zillaslab/zillaslabhighlight-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'pacifico';
    src: url('fonts/pacifico/pacifico-webfont.woff2') format('woff2'),
         url('fonts/pacifico/pacifico-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lilitaone';
    src: url('fonts/lilita-one/lilitaone-regular-webfont.woff2') format('woff2'),
         url('fonts/lilita-one/lilitaone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'alegreya_sans';
    src: url('fonts/alegreya-sans/alegreyasans-thin-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans/alegreyasans-thin-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sans';
    src: url('fonts/alegreya-sans/alegreyasans-light-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans/alegreyasans-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sans';
    src: url('fonts/alegreya-sans/alegreyasans-regular-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans/alegreyasans-regular-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sans';
    src: url('fonts/alegreya-sans/alegreyasans-medium-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans/alegreyasans-medium-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sans';
    src: url('fonts/alegreya-sans/alegreyasans-bold-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans/alegreyasans-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sans';
    src: url('fonts/alegreya-sans/alegreyasans-extrabold-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans/alegreyasans-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sans';
    src: url('fonts/alegreya-sans/alegreyasans-black-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans/alegreyasans-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}


@font-face {
    font-family: 'alegreya_sans_sc';
    src: url('fonts/alegreya-sans-sc/alegreyasanssc-thin-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans-sc/alegreyasanssc-thin-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sans_sc';
    src: url('fonts/alegreya-sans-sc/alegreyasanssc-light-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans-sc/alegreyasanssc-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sans_sc';
    src: url('fonts/alegreya-sans-sc/alegreyasanssc-regular-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans-sc/alegreyasanssc-regular-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sans_sc';
    src: url('fonts/alegreya-sans-sc/alegreyasanssc-medium-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans-sc/alegreyasanssc-medium-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sans_sc';
    src: url('fonts/alegreya-sans-sc/alegreyasanssc-bold-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans-sc/alegreyasanssc-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sans_sc';
    src: url('fonts/alegreya-sans-sc/alegreyasanssc-extrabold-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans-sc/alegreyasanssc-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sans_sc';
    src: url('fonts/alegreya-sans-sc/alegreyasanssc-black-webfont.woff2') format('woff2'),
         url('fonts/alegreya-sans-sc/alegreyasanssc-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}



body {
    font-family: 'zilla_slab', serif;
    font-weight: 500;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'panefresco', sans-serif;
    font-weight: 100;
    margin-bottom: 0;
}

h1, .fs-1 {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 28px;
    line-height: 1.75rem;
}

h2, .fs-2 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 24px;
    line-height: 1.5rem;
}

h3, .fs-3 {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
}

h4, .fs-4 {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 20px;
    line-height: 1.25rem;
}

h5, .fs-5 {
    font-size: 12px;
    font-size: 0.8rem;
    line-height: 18px;
    line-height: 2.125rem;
}

h6, .fs-6 {
    font-size: 10px;
    font-size: 0.625rem;
    line-height: 16px;
    line-height: 1rem;
}


@media only screen and (min-width: 46em) {
    h1, .fs-1 {
        font-size: 26px;
        font-size: 1.625rem;
        line-height: 32px;
        line-height: 2rem;
    }

    h2, .fs-2 {
        font-size: 22px;
        font-size: 1.375rem;
        line-height: 28px;
        line-height: 1.75rem;
    }

    h3, .fs-3 {
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 24px;
        line-height: 1.5rem;
    }

    h4, .fs-4 {
        font-size: 16px;
        font-size: 1rem;
        line-height: 22px;
        line-height: 1.375rem;
    }

    h5, .fs-5 {
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 20px;
        line-height: 1.25rem;
    }

    h6, .fs-6 {
        font-size: 12px;
        font-size: 0.75rem;
        line-height: 18px;
        line-height: 1.125rem;
    }
}